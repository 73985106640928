import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";

const MobileNavMenu = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/#categories");
  };
  // const onClickHandler = (e) => {
  //   const target = e.currentTarget;
  //   const parentEl = target.parentElement;
  //   if (
  //     parentEl?.classList.contains("menu-toggle") ||
  //     target.classList.contains("menu-toggle")
  //   ) {
  //     const element = target.classList.contains("icon") ? parentEl : target;
  //     const parent = getClosest(element, "li");
  //     const childNodes = parent.childNodes;
  //     const parentSiblings = getSiblings(parent);
  //     parentSiblings.forEach((sibling) => {
  //       const sibChildNodes = sibling.childNodes;
  //       sibChildNodes.forEach((child) => {
  //         if (child.nodeName === "UL") {
  //           slideUp(child, 1000);
  //         }
  //       });
  //     });
  //     childNodes.forEach((child) => {
  //       if (child.nodeName === "UL") {
  //         slideToggle(child, 1000);
  //       }
  //     });
  //   }
  // };
  return (
    <nav className="site-mobile-menu">
      <ul>
        <li className="has-children">
          <NavLink to={process.env.PUBLIC_URL + "/"}>
            <span className="menu-text">Homepage</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/about"}>
            <span className="menu-text">About Us</span>
          </NavLink>
        </li>

        <li className="has-children">
          <NavLink>
            <span onClick={handleButtonClick} className="menu-text">
              {" "}
              Categories
            </span>
          </NavLink>

          <ul className="sub-menu">
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/cognitive-abilities"}>
                <span className="menu-text">CogAt</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/robotics"}>
                <span className="menu-text">Robotics</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/math-analysis"}>
                <span className="menu-text">Math</span>
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/contact"}>
            <span className="menu-text">Contact Us</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/resources"}>
            <span className="menu-text">Resources</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
