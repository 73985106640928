import React, { useState } from "react";
import { envData } from "../../envs";
import { styled } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Typography } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function StudentUploadPage() {
  const [files, setFiles] = useState([]);
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleDownload = () => {
    const token = localStorage.getItem("token");
    fetch(`${envData.baseApiUrl}/v1/students/csv/template/download`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob(); // Convert response to Blob
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "template.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (files.length == 0) {
      alert("Please select a file to upload");
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    formData.append("type", "upload");

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${envData.baseApiUrl}/v1/students/student/upload`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
          body: formData,
        }
      );
      if (response.ok) {
        const res = await response.json();
        setOpen(true);
        setMsg(res);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setMsg(error);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const errorAlert = msg?.msg?.includes("success");

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "20px",
          background: "#001F3F",
          margin: "10px auto",
          width: "90%",
          justifyContent: "space-around",
          flexDirection: isMobile ? "column" : "",
        }}
      >
        <div>
          <Button
            onClick={handleDownload}
            variant="contained"
            startIcon={<CloudDownloadIcon />}
          >
            Download <br /> template file
          </Button>
        </div>
        <div>
          <div style={{ marginTop: "10px" }}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              style={{ padding: "7px 24px" }}
            >
              Upload file
              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </Button>
            <br />
            <Typography style={{ height: "23px" }}>
              {files["0"]?.name &&
                files["0"]?.name.slice(0, 7) +
                  "." +
                  files["0"]?.name.split(".").pop()}
            </Typography>
          </div>
          <Button
            style={{ padding: "7px 27px" }}
            type="submit"
            variant="contained"
            color="success"
            onClick={handleSubmit}
          >
            <CheckCircleOutlineIcon />
            Submit file
          </Button>
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        <Snackbar
          key={"center" + "center"}
          anchorOrigin={{ vertical: "center", horizontal: "center" }}
          open={open}
          autoHideDuration={msg?.msg?.includes("already") ? 6000 : 4000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={!errorAlert ? "warning" : "success"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {msg && msg?.msg}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}
