import React, { useEffect, useState } from "react";
import axios from "axios";
import { envData } from "../../../envs";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

// import { config } from "@/config";
import { AccountDetailsForm } from "./account-details-form";
import { AccountInfo } from "./account-info";
import { isMobile } from "react-device-detect";

// export const metadata = { title: `Account | Dashboard | ${config.site.name}` };

export default function InstructorProfilePage() {
  const [userProfileData, setProfileData] = useState("");

  const getUserInstructor = async () => {
    const token = localStorage.getItem("token");
    try {
      const request2 = await axios.get(`${envData.baseApiUrl}/v1/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProfileData(request2);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    getUserInstructor();
  }, []);
  return (
    <Stack spacing={3} style={{ width: isMobile ? "100%" : "50%" }}>
      <div>
        <Typography variant="h5" style={{ padding: "10px", color: "#52668C" }}>
          My Account
        </Typography>
      </div>
      {userProfileData && (
        <Grid container spacing={3}>
          <Grid lg={4} md={6} xs={12}>
            <AccountInfo userProfileData={userProfileData} />
          </Grid>
          <Grid lg={8} md={6} xs={12}>
            <AccountDetailsForm userProfileData={userProfileData} />
          </Grid>
        </Grid>
      )}
    </Stack>
  );
}
