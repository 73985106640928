import React from "react";
import { BiFontSize } from "react-icons/bi";
import { NavLink } from "react-router-dom";

const NavBar = () => {
  const navSize = { fontSize: "20px" };
  return (
    <nav className="site-main-menu">
      <ul>
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/"}>
            <span style={navSize} className="menu-text">
              Homepage
            </span>
          </NavLink>
        </li>
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/about"}>
            <span className="menu-text" style={navSize}>
              About
            </span>
          </NavLink>
        </li>
        {/* <li>
          <NavLink to={process.env.PUBLIC_URL + "/service"}>
            <span className="menu-text" style={navSize}>
              Services
            </span>
          </NavLink>
        </li> */}
        <li className="has-children">
          <NavLink to={process.env.PUBLIC_URL + "/"}>
            <span className="menu-text" style={navSize}>
              Categories
            </span>
          </NavLink>
          <span className="menu-toggle">
            <i className="far fa-angle-down"></i>
          </span>
          <ul className="sub-menu">
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/cognitive-abilities"}>
                <span className="menu-text">CogAt</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/robotics"}>
                <span className="menu-text">Robotics</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/math-analysis"}>
                <span className="menu-text">Math</span>
              </NavLink>
            </li>
          </ul>
        </li>
        {/* <li className="has-children">
          <NavLink to={process.env.PUBLIC_URL + "/blog-grid"}>
            <span className="menu-text" style={navSize}>
              Blog
            </span>
          </NavLink>
          <span className="menu-toggle">
            <i className="far fa-angle-down"></i>
          </span>
          <ul className="sub-menu">
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/blog-grid"}>
                <span className="menu-text">Blog Grid</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/blog-classic"}>
                <span className="menu-text">Blog classic</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + `/blog-details/1`}>
                <span className="menu-text">Blog Details</span>
              </NavLink>
            </li>
          </ul>
        </li> */}
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/contact"}>
            <span className="menu-text" style={navSize}>
              Contact Us
            </span>
          </NavLink>
        </li>
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/resources"}>
            <span className="menu-text" style={navSize}>
              Resources
            </span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
