import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function StudentFilterComponent({
  studentData,
  getSelectedSchoolName,
  openSchoolList,
  openSchoolListForFiltering,
}) {
  const [open, setOpen] = React.useState(openSchoolList);
  const [selectedSchool, setSelectedSchool] = React.useState("");
  React.useEffect(() => {
    setOpen(openSchoolList);
  }, [openSchoolList]);

  const handleClose = () => {
    setOpen(false);
    openSchoolListForFiltering(false);
  };

  const handleSchoolChange = (event) => {
    setSelectedSchool(event.target.value);
    getSelectedSchoolName(event.target.value);

    openSchoolListForFiltering(false);
    setOpen(false);
  };

  const schoolList = [
    "All schools",
    ...new Set(studentData.map((student) => student.schoolName)),
  ];
  return (
    <React.Fragment>
      <Dialog fullWidth={false} maxWidth="sm" open={open} onClose={handleClose}>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <FormControl sx={{ mt: 2, minWidth: 220 }}>
              <InputLabel htmlFor="max-width">School</InputLabel>
              <Select
                autoFocus
                value={selectedSchool}
                onChange={handleSchoolChange}
                label="maxWidth"
                inputProps={{
                  name: "max-width",
                  id: "max-width",
                }}
              >
                {schoolList.map((school, ind) => {
                  return (
                    <MenuItem
                      key={school}
                      value={school}
                      style={{ backgroundColor: ind === 0 ? "lightGrey" : "" }}
                    >
                      {school}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
