import React, { useState, useEffect } from "react";
import axios from "axios";
import { envData } from "../../envs";
import StudentTablePage from "./StudentsTablePage";
import Alert from "@mui/material/Alert";
import { CSVLink, CSVDownload } from "react-csv";
import IosShareIcon from "@mui/icons-material/IosShare";
import Box from "@mui/material/Box";
import { isMobile } from "react-device-detect";

import StudentUploadPage from "./StudentUploadPage";
import StudentFilterComponent from "./StudentFilterComponent";

function StudentListPage() {
  const [filtered, setSearchValue] = useState("");
  const [studentData, setStudentData] = useState("");
  const [selectedSchoolName, setSchool] = useState("");
  const [openSchoolList, setOpenSchoolList] = useState(false);

  const getSelectedSchoolName = (schoolName) => {
    setSchool(schoolName);
  };
  const openSchoolListForFiltering = (e) => {
    setOpenSchoolList(e);
  };
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const getAvailableExamsAndRegisteredStudents = async () => {
    const token = localStorage.getItem("token");
    try {
      const [request1, request2] = await Promise.all([
        axios.get(`${envData.baseApiUrl}/v1/info/exams`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${envData.baseApiUrl}/v1/students`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setStudentData(request2.data);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  useEffect(() => {
    getAvailableExamsAndRegisteredStudents();
  }, []);

  const filteredBySchool = () => {
    if (!studentData.length) {
      return "";
    }
    if (selectedSchoolName.toLowerCase() === "all schools") {
      return studentData;
    } else {
      return studentData.filter((student) =>
        student.schoolName
          .toLowerCase()
          .includes(selectedSchoolName.toLowerCase())
      );
    }
  };
  return (
    <div>
      <StudentUploadPage />
      <div
        style={{
          margin: "10px auto",
          width: "90%",
        }}
      >
        {filteredBySchool().length ? (
          <>
            <Box
              style={{
                display: "flex",
                marginTop: "5px",
                justifyContent: "space-between",
              }}
            >
              <input
                style={{
                  margin: "3px 0px",
                  width: isMobile ? "65%" : "50%",
                }}
                type="text"
                placeholder="Search student by name..."
                value={filtered}
                onChange={handleSearch}
              />

              <Box style={{ marginLeft: "15px", textAlign: "center" }}>
                <CSVLink data={filteredBySchool()}>
                  <IosShareIcon
                    style={{ fontSize: "25px", color: "#1292EE" }}
                  />
                  <br />
                  Export to file
                </CSVLink>
              </Box>
            </Box>
            <div>
              <StudentFilterComponent
                studentData={studentData}
                getSelectedSchoolName={getSelectedSchoolName}
                openSchoolList={openSchoolList}
                openSchoolListForFiltering={openSchoolListForFiltering}
              />
            </div>
            <StudentTablePage
              studentData={filteredBySchool()}
              filtered={filtered}
              openSchoolListForFiltering={openSchoolListForFiltering}
            />
          </>
        ) : (
          <Alert severity="info">
            There is no registered students to show at this time.
          </Alert>
        )}
      </div>
    </div>
  );
}

export default StudentListPage;
