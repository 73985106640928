import React from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/joy/Card";
import PayMethod from "./paymentMethod";
import Button from "@mui/material/Button";
import { isMobile } from "react-device-detect";

function PaymentPage() {
  const navigate = useNavigate();
  const getAddedExamsFromSession =
    sessionStorage.getItem("exams") &&
    JSON.parse(sessionStorage.getItem("exams"));
  const logoImage = (examName) => {
    if (examName === "Math") {
      return "images/bg/math.png";
    }
    if (examName === "CogAt") {
      return "images/bg/breadcrumb-bg-two1.png";
    }
    return "images/bg/robotix.png";
  };
  const handleExamRemove = (exam) => {
    const array = getAddedExamsFromSession.filter(
      (item) => item.name !== exam.name
    );
    // Step 3: Save the updated array back to sessionStorage
    sessionStorage.setItem("exams", JSON.stringify(array));
    if (getAddedExamsFromSession.length === 1) {
      navigate("/dash");
    } else {
      window.location.reload();
    }
  };
  return (
    <>
      <div className="container">
        <div
          className="row"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            style={{
              color: "white",
              fontSize: "20px",
              textAlign: "center",
              padding: "3px",
              fontWeight: "bold",
            }}
          >
            You have added
          </Typography>
          <Box
            style={{
              alignItems: "center",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {getAddedExamsFromSession &&
              getAddedExamsFromSession.map((exam) => {
                return (
                  <Card
                    key={exam.id}
                    style={{
                      width: isMobile
                        ? `${300 / getAddedExamsFromSession.length}px`
                        : "250px",
                      height: "100px",
                      alignItems: "center",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px",
                      boxShadow: "3px 3px 1px #222",
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/${logoImage(exam.name)}`}
                      alt="exam.name"
                    />
                    <Button
                      style={{
                        width: isMobile
                          ? `${270 / getAddedExamsFromSession.length}px`
                          : "230px",
                      }}
                      variant="outlined"
                      onClick={() => handleExamRemove(exam)}
                    >
                      remove
                    </Button>
                  </Card>
                );
              })}
          </Box>
          {getAddedExamsFromSession && <PayMethod />}
        </div>
      </div>
    </>
  );
}

export default PaymentPage;
