import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Cogat from "./Cogat.pdf";
import Math from "./Math.pdf";

const BlogItem = ({ data, key, disabledSubject }) => {
  const showHideBlog = (type) => {
    if (type.flag === "other" || type.flag === "robotics") {
      return true;
    }
    return false;
  };
  return (
    <div className="blog">
      <div className="thumbnail" style={{ padding: "10px" }}>
        <img
          height={data.flag === "other" ? "230px" : ""}
          src={process.env.PUBLIC_URL + data.image}
          alt="Blog Image"
        />
      </div>
      <div className="info">
        <ul className="meta">
          {!disabledSubject ? (
            <li>
              <i className="far fa-calendar"></i>
              {data.date}
            </li>
          ) : (
            ""
          )}
          {!disabledSubject ? (
            !showHideBlog(data) ? (
              <li>
                <i className="far fa-eye"></i>
                {+data.view} views
              </li>
            ) : (
              <li>
                <i className="far fa-eye"></i>
                {data.view}{" "}
              </li>
            )
          ) : (
            ""
          )}
        </ul>
        {!disabledSubject ? <h3 className="title">{data.title}</h3> : ""}

        {!showHideBlog(data) ? (
          <a
            style={{ textDecoration: "underline", color: "#1292EE" }}
            href={data.flag === "math" ? Math : Cogat}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download mock papers
          </a>
        ) : (
          <Link to={process.env.PUBLIC_URL + `/resources/${data.id}`}>
            {data.flag === "robotics" ? "Visit later" : "Read more..."}
          </Link>
        )}
      </div>
    </div>
  );
};

BlogItem.propTypes = {
  data: PropTypes.object,
};

export default BlogItem;
