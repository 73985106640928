import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import LineFollowerRobot from "./LineFollowerRobot.pdf";
import PayloadCarryingRobot from "./PayloadCarryingRobot.pdf";

export default function RobotixCatComponent() {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{ fontWeight: "bold" }}
        >
          Line Follower Robot for Grades 6-8
        </AccordionSummary>
        <AccordionDetails>
          <a
            style={{ textDecoration: "underline", color: "#1292EE" }}
            href={LineFollowerRobot}
            target="_blank"
            rel="noopener noreferrer"
          >
            Here is sample clue for building line follower
          </a>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{ fontWeight: "bold" }}
        >
          Payload Carrying Robot for Grades 9-12
        </AccordionSummary>
        <AccordionDetails>
          <a
            style={{ textDecoration: "underline", color: "#1292EE" }}
            href={PayloadCarryingRobot}
            target="_blank"
            rel="noopener noreferrer"
          >
            Here is sample clue for building Payload Carrier
          </a>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
