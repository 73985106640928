import React, { useState } from "react";
import { fakeAuthProvider } from "../LoginActions";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://xceledu.ai/">
        xceledu.ai
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignInSide() {
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [errorMessage, setError] = useState("");
  const [userData, setUserLoginData] = useState({
    email: "",
    password: "",
    isInstructor: false,
  });
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleUserLogin = async (e) => {
    e.preventDefault();
    setSpinner(true);
    setError("");
    try {
      const response = await fakeAuthProvider.signin(userData);
      if (!response) {
        setError("Please try one more time");
        setSpinner(false);
        return;
      }
      setError("");
      setSpinner(false);
      navigate("/dash");
    } catch (error) {
      console.error("Error occurred:", error);
      setSpinner(false);
      setError("Please try one more time");
      return;
    }
  };
  const handleLogin = (e) => {
    if (e.target.name === "isInstructor") {
      setUserLoginData({ ...userData, isInstructor: e.target.checked });
      return;
    }
    setUserLoginData({ ...userData, [e.target.name]: e.target.value });
  };
  const handleBacktoHome = () => navigate("/");
  const redirectRegister = () => {
    // fakeAuthProvider.signout();
    navigate("/register");
  };
  return (
    <>
      {spinner ? (
        <Box
          sx={{
            width: "100%",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ThemeProvider theme={defaultTheme}>
          <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/hero-image/hero-111.png)`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
            >
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: "center",
                }}
              >
                <Box style={{ marginBottom: "8px", paddingBottom: "5px" }}>
                  <img
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    // sx={{ m: 1, bgcolor: "#DB1F48" }}
                    src={`${process.env.PUBLIC_URL}/images/hero-image/main-logo.png`}
                  />
                </Box>
                <Typography
                  component="h1"
                  style={{ color: "#52668C", fontSize: "18px" }}
                >
                  Welcome back
                </Typography>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleUserLogin}
                  sx={{ mt: 1 }}
                >
                  <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                    <InputLabel htmlFor="outlined-adornment-email">
                      Email
                    </InputLabel>
                    <OutlinedInput
                      autoFocus
                      required
                      // fullWidth
                      name="email"
                      id="outlined-adornment-email"
                      value={userData.email}
                      onChange={handleLogin}
                      type="text"
                      label="Email Address"
                    />
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      required
                      // fullWidth
                      name="password"
                      id="outlined-adornment-password"
                      value={userData.password}
                      onChange={handleLogin}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleLogin}
                        value="instructor"
                        color="primary"
                        name="isInstructor"
                      />
                    }
                    label="Teacher/Supervisor"
                  />
                  {errorMessage && (
                    <Box
                      sx={{
                        width: "100%",
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography sx={{ color: "#DB1F48", fontWeight: "bold" }}>
                        {errorMessage}
                      </Typography>
                    </Box>
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: "#A46080" }}
                    sx={{ mt: 3, mb: 2 }}
                    disabled={!userData.email || !userData.password}
                  >
                    Sign In
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    style={{ backgroundColor: "#52668C", color: "white" }}
                    variant="outlined"
                    sx={{ mt: 3, mb: 1 }}
                  >
                    <Link onClick={redirectRegister} variant="body2">
                      {"Don't have an account? Create."}
                    </Link>
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 3, mb: 1 }}
                    onClick={handleBacktoHome}
                  >
                    <AccountBalanceIcon style={{ color: "#52668C" }} />
                    <span style={{ margin: "0 3px", color: "#52668C" }}></span>
                    Back to Home page
                  </Button>
                  <Copyright sx={{ mt: 5 }} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      )}
    </>
  );
}
