import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { MdAddCircleOutline } from "react-icons/md";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";

import { createSvgIcon } from "@mui/material/utils";

const PlusIcon = createSvgIcon(
  // credit: plus icon from https://heroicons.com/
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5v15m7.5-7.5h-15"
    />
  </svg>,
  "Plus"
);

export default function SubjectCardsForRegistration({
  alreadyAddedExams,
  data,
  handleClickOpen,
  handleExamAdd,
}) {
  const handleTestAdd = (bool, testType, examId) => {
    handleClickOpen(bool, testType, examId);
  };
  const disableIfExamAdded = alreadyAddedExams.includes(data.examName);
  const examIsInSession = JSON.parse(sessionStorage.getItem("exams"));
  const logoImage = (examName) => {
    if (examName === "Math") {
      return "images/bg/math.png";
    }
    if (examName === "CogAt") {
      return "images/bg/breadcrumb-bg-two1.png";
    }
    return "images/bg/robotix.png";
  };
  return (
    <>
      {data && (
        <div
          className="col-sm-3 col-12 mb-4"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            sx={{
              fontSize: "2rem",
              maxWidth: 345,
              borderRadius: "5%",
              boxShadow: "2px 2px 15x 10px grey",
              color: "Black",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/${logoImage(data.examName)}`}
              alt={data.examName}
              style={{
                padding: "10px",
                // boxShadow: "0 4px 4px rgba(0, 0, 0, 0.4)",
                marginBottom: "3px",
              }}
            />
            <Divider
              sx={{
                borderBottomWidth: 3,
                bgcolor: "black",
                margin: "3px 5px",
              }}
            />
            {/* <CardHeader
            // avatar={
            //   <Avatar
            //     src={`${process.env.PUBLIC_URL}/images/bg/robotix.png`}
            //     // sx={{ bgcolor: red[500], width: 50 }}
            //     aria-label="recipe"
            //   ></Avatar>
            // }
            // sx={{ fontWeight: "bold" }}
            // titleTypographyProps={{ variant: "h6" }}
            // title={data.examName}
            >
             
            </CardHeader> */}
            <CardMedia
              component="img"
              image={`${process.env.PUBLIC_URL}/images/patternSmall.png`}
              alt={data.examName}
            />
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <Button
                disabled={
                  !data.isActive ||
                  disableIfExamAdded ||
                  (examIsInSession &&
                    examIsInSession.find((i) => i.name === data.examName))
                  // (examIsInSession && examIsInSession.includes(data.examName))
                }
                // style={{
                //   backgroundColor: "#4133B7",
                //   color: disableIfExamAdded ? "grey" : "",
                // }}
                variant="contained"
                endIcon={<PlusIcon />}
                // onClick={() => handleTestAdd(true, data.examName, data.examId)}
                onClick={() => handleExamAdd(data)}
              >
                Add test
              </Button>
            </Box>
          </Card>
        </div>
      )}
    </>
  );
}
